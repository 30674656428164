export class FetchAllNotifications {
  static readonly type = '[Notifications] Fetch all notifications';
}

export class MarkAsRead {
  static readonly type = '[Notifications] Mark As Read';
  constructor(public id: string) {}
}

export class FetchUnreadNotificationCount {
  static readonly type = '[Notifications] Fetch Unread Count';
}
