import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {NotificationUIRestService} from 'projects/notifications/src/app/core/api/generated/msa-notification';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {FetchAllNotifications, FetchUnreadNotificationCount, MarkAsRead} from '../actions/notifications.state.actions';
import {NotificationsStateModel} from '../models/notifications.state.model';

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [],
    unreadCount: 0
  }
})
@Injectable()
export class NotificationsState {
  constructor(
    private notificationService: NotificationUIRestService,
    private errorMessageHandler: ErrorMessageHandler
  ) {}

  @Action(FetchAllNotifications)
  fetchAllNotifications(ctx: StateContext<NotificationsStateModel>) {
    return this.notificationService.getAllNotifications().pipe(
      tap(notifications => {
        ctx.patchState({notifications});
      }),
      catchError((err: unknown) => this.errorMessageHandler.logAndIgnore(err))
    );
  }

  @Action(MarkAsRead)
  markAsRead(ctx: StateContext<NotificationsStateModel>, {id}: MarkAsRead) {
    if (!id) {
      throw new Error('Attempted to mark a notification as read without an id');
    }

    return this.notificationService.setReadAt(id).pipe(
      tap(response => {
        const state = ctx.getState();
        const updatedNotifications = state.notifications.map(notification => {
          if (notification.id === id) {
            return {...notification, readAt: new Date().toISOString()};
          }
          return notification;
        });

        ctx.patchState({
          notifications: updatedNotifications,
          unreadCount: response.number
        });
      }),
      catchError((error: unknown) => {
        console.warn('Error marking notification as read:', error);
        return of(null);
      })
    );
  }

  @Action(FetchUnreadNotificationCount)
  fetchUnreadNotificationCount(ctx: StateContext<NotificationsStateModel>) {
    return this.notificationService.countUnread().pipe(
      tap(unreadCount => {
        ctx.patchState({unreadCount: unreadCount.number});
      }),
      catchError((err: unknown) => this.errorMessageHandler.logAndIgnore(err))
    );
  }
}
