import {createPropertySelectors, Selector} from '@ngxs/store';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {chain, orderBy} from 'lodash';
import moment from 'moment';
import {Language, RequestType} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {
  NotificationDto,
  NotificationTypeDto,
  TranslatedTextsDto
} from 'projects/notifications/src/app/core/api/generated/msa-notification';
import {NotificationsStateModel} from '../models/notifications.state.model';
import {NotificationsState} from '../states/notifications.state';
import {AppStateSelectors} from './app.state.selectors';

export interface NotificationView {
  unread: boolean;
  creationDateTime: string;
  subject: string;
  body: string;
  buttonText: TranslationString;
  buttonLink: string;
  referenceId: string;
  id: string;
}

export class NotificationsStateSelectors {
  static readonly slices = createPropertySelectors<NotificationsStateModel>(NotificationsState);

  @Selector([NotificationsStateSelectors.slices.notifications])
  static notifications(notifications: NotificationDto[]): NotificationDto[] {
    return notifications;
  }

  @Selector([NotificationsStateSelectors.slices.unreadCount])
  static unreadCount(unreadCount: number): number {
    return unreadCount;
  }

  @Selector([NotificationsStateSelectors.slices.notifications, AppStateSelectors.slices.language])
  static groupedNotifications(
    notifications: NotificationDto[],
    language: Language
  ): Array<{month: string; notifications: NotificationView[]}> {
    const notificationViews: NotificationView[] = this.mapDtoToView(notifications, language);

    return (
      chain(notificationViews)
        .groupBy(n => moment(n.creationDateTime).startOf('month')) // does not guarantee order!
        .entries()
        // order groups by year-month, in descending order
        .orderBy(([month]) => moment(month).format('YYYY-MM'), 'desc')
        .map(([month, groupNotifications]) => {
          return {
            month: moment(month).locale(language).format('MMMM YYYY'), // format for shwowing "Januar 2024"
            notifications: orderBy(groupNotifications, n => n.creationDateTime, 'desc'),
            language
          };
        })
        .value()
    );
  }

  private static mapDtoToView(notifications: NotificationDto[], language: Language): NotificationView[] {
    const languageString: string = this.getLanguage(language);

    return notifications.map(notification => ({
      unread: !notification.readAt,
      creationDateTime: notification.creationDateTime || '',
      subject: notification.subject ? this.getTranslatedText(notification.subject, languageString) : '',
      body: notification.body ? this.getTranslatedText(notification.body, languageString) : '',
      buttonText: this.getButtonText(notification.notificationType, notification.referenceId),
      buttonLink: this.getButtonLink(
        notification.notificationType,
        notification.referenceId,
        notification.requestType ? notification.requestType : ''
      ),
      referenceId: notification.referenceId || '',
      id: notification.id || ''
    }));
  }

  private static getTranslatedText(textDto: TranslatedTextsDto, lang: string): string {
    switch (lang) {
      case 'DE':
        return textDto.DE;
      case 'FR':
        return textDto.FR;
      case 'IT':
        return textDto.IT;
      default:
        return '';
    }
  }

  private static getLanguage(language: Language): string {
    switch (language) {
      case Language.De:
        return 'DE';
      case Language.Fr:
        return 'FR';
      case Language.It:
        return 'IT';
      default:
        return 'DE';
    }
  }

  private static getButtonText(type: NotificationTypeDto | undefined, referenceId?: string): TranslationString {
    switch (type) {
      case NotificationTypeDto.Duty:
        return referenceId
          ? 'i18n.notifications.overview.buttons.open-duty'
          : 'i18n.notifications.overview.buttons.open-duty-overview';
      case NotificationTypeDto.Request:
        return 'i18n.notifications.overview.buttons.open-request';
      case NotificationTypeDto.MilitaryData:
        return 'i18n.notifications.overview.buttons.open-military-data';
      case NotificationTypeDto.General:
        return 'i18n.notifications.overview.buttons.open';
      default:
        return 'i18n.notifications.overview.buttons.open';
    }
  }

  private static getButtonLink(
    type: NotificationTypeDto | undefined,
    referenceId: string | undefined,
    rquestType: string | undefined
  ): string {
    switch (type) {
      case NotificationTypeDto.Duty:
        return referenceId ? 'admin-query/duties/' + referenceId : 'admin-query/duties/';
      case NotificationTypeDto.Request:
        if (rquestType === RequestType.Leave) {
          return 'admin-query/requests/leave/' + referenceId;
        } else {
          return 'admin-query/requests/shift/' + referenceId;
        }
      case NotificationTypeDto.MilitaryData:
        return 'information/military';
      case NotificationTypeDto.General:
        return '';
      default:
        return '';
    }
  }
}
